<template>
    <div class="page-404">
        
    </div>
</template>
 
<script>
 
export default {
    data() {
        return {}
    },
   methods: {
        
    }
}
</script>
 
<style scoped>
 .page-404{
     height: 100vh;
     width: 100vw;
     background: url('../../assets/images/404.jpg') no-repeat;
     background-color: #eff2f7;
     background-size: cover;
 }
</style>